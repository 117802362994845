<template>
  <div class="content mt-3 bottom-element">
    <h4 class="text-uppercase">Manage User Portal Logins</h4>
    <div class="partner-users-view">
      <div class="border-bottom mb-4"></div>
      <div class="row">
        <div data-widget="dirtyforms" class="col dirtylisten">

          <div v-for="(user, index) in partnerUsers" v-bind:key="index" class="row" :id="'user-row-'+user.uuid">
            <div class="col-2">
              <input :value="user.first_name" name="first_name" class="form-control form-control-edit" placeholder="Firstname" type="text" autocomplete="off" id="first_name">
              <label for="first_name" class="error">&nbsp;</label>
            </div>
            <div class="col-2">
              <input :value="user.last_name" name="last_name" class="last-name form-control form-control-edit" placeholder="Lastname" type="text" autocomplete="off" id="last_name">
              <label for="last_name" class="error">&nbsp;</label>
            </div>
            <div class="col-2">
              <input :value="user.email" name="email" class="form-control form-control-edit" placeholder="emailAddress" type="email" id="email">
              <label for="email" class="error">&nbsp;</label>
            </div>
            <div class="col-2">
              <select :value="user.role_id" name="role" class="form-control app-type" data-provider="selectpicker" autocomplete="off" id="DealsFilter[appType]" tabindex="-98">
                <option value="partner_user">User</option>
                <option value="partner_admin">Admin</option>
              </select>
              <label for="role" class="error">&nbsp;</label>
            </div>
            <div class="col-2 d-noflex">
              <button v-on:click="this()" type="submit" class="align-self-end btn btn-block btn-dark btn-no-bold pull-right">Update</button>
            </div>
            <div class="col-2 d-noflex">
              <button v-on:click="this()" :disabled="user.email == $store.getters.getProfile.email" type="submit" class="align-self-end btn btn-block btn-dark btn-no-bold pull-right">Remove</button>
            </div>
          </div>
        </div>
      </div>
      <div class="border-bottom mb-4"></div>
      <div class="row">
        <div data-widget="dirtyforms" class="col dirtylisten">
          <div class="row" :id="'user-row-create'">
            <div class="col-2">
              <input name="first_name" class="form-control form-control-edit" placeholder="Firstname" type="text" autocomplete="off">
              <label for="first_name" class="error">&nbsp;</label>
            </div>
            <div class="col-2">
              <input name="last_name" class="last-name form-control form-control-edit" placeholder="Lastname" type="text" value="" autocomplete="off">
              <label for="last_name" class="error">&nbsp;</label>
            </div>
            <div class="col-2">
              <input name="email" class="form-control form-control-edit" placeholder="emailAddress" type="text">
              <label for="email" class="error">&nbsp;</label>
            </div>
            <div class="col-2">
              <select name="role" id="role" class="form-control app-type" data-provider="selectpicker" autocomplete="off" tabindex="-98">
                <option value="partner_user">User</option>
                <option value="partner_admin">Admin</option>
              </select>
              <label for="role" class="error">&nbsp;</label>
            </div>
            <div class="col-2">
              <input name="password" class="form-control form-control-edit" placeholder="password" type="password" id="password">
              <label for="password" class="error">&nbsp;</label>
            </div>
            <div class="col-2 d-noflex">
              <button v-on:click="this()" type="submit" class="align-self-end btn btn-block btn-dark btn-no-bold pull-right">Create</button>
            </div>
          </div>
        </div>
      </div>

    </div>
  </div>
</template>

<script>
// TODO: empty, unused YET
export default {
  name: 'UserList',
  methods: {}
}
</script>
