<template>
  <div class="container-lg kap-container company-color">
    <div v-if="loading" class="content"> Loading...</div>
    <div v-if="!loading" class="content">
      <div class="row mb-3">
        <div class="col-lg-8">
          <h4><span class="text-uppercase">{{ partner.name }}</span></h4>
        </div>
        <div class="col-lg-4 text-sm-left text-lg-right">
          <div role="group" class="btn-group mr-05">
            <router-link v-if="visibility.partner_edit_link" :to="'/partner/' + partner.uuid + '/edit'" class="btn btn-link company-link-color">Edit Info</router-link>
            <router-link to="/password/change" class="btn btn-primary big-button kap-button company-link-bg">Change Password</router-link>
          </div>
        </div>
      </div>
      <div class="border-bottom mb-4"></div>
      <div class="row">
        <div class="col-md-12 col-lg-6" v-if="isParamNotEmpty('address', partner)">
          <h5>BUSINESS ADDRESS</h5>
          <span v-if="isParamNotEmpty('address.address_1', partner)"><strong>ADDRESS LINE 1:</strong> {{ getParamValue('address.address_1', partner) }}<br></span>
          <span v-if="isParamNotEmpty('address.address_2', partner)"><strong>ADDRESS LINE 2:</strong> {{ getParamValue('address.address_2', partner) }}<br></span>
          <span v-if="isParamNotEmpty('address.city', partner)"><strong>CITY:</strong> {{ getParamValue('address.city', partner) }}<br></span>
          <span v-if="isParamNotEmpty('address.state', partner)"><strong>STATE:</strong> {{ getParamValue('address.state', partner) }}<br></span>
          <span v-if="isParamNotEmpty('address.postal_code', partner)"><strong>ZIP:</strong> {{ getParamValue('address.postal_code', partner) }}</span>
        </div>

        <div class="col-md-12 col-lg-6">
          <h5>CONTACTS</h5>
          <div v-for="(contact, index) in partner.contacts" v-bind:key="index" class="mb-3">
                            <span v-if="isParamNotEmpty('last_name', contact)">
                                <strong>NAME:</strong> {{ getParamValue('first_name', contact) }} {{ getParamValue('last_name', contact) }} <br>
                            </span>
            <span v-if="isParamNotEmpty('emails', contact)"><strong>EMAIL:</strong> {{ getListParamValue('emails', 'email', contact) }}<br></span>
            <span v-if="isParamNotEmpty('phonenumbers', contact)"> <strong>PHONE:</strong> {{ getListParamValue('phonenumbers', 'number', contact) }}<br></span>
          </div>
        </div>
      </div>
    </div>
    <div v-if="visibility.widget_assoc_partners && !loading" class="content mt-3">
      <div class="row">
        <div class="col-md-5">
          <h4 class="text-uppercase">My associated partners</h4>
        </div>
        <div class="col-md-7">
          <form action="" id="filter-form" class="form form-inline filter d-flex pull-right" method="get" accept-charset="utf-8">
            <div class="filter-reset p-3">
              <a class="filter-clear datatable-filter" v-bind:class="{ 'disabled': resetFilterDisabled }" v-on:click="cleanAll">Reset All Filters</a>
            </div>
            <div class="mr-1">

              <div class="dropdown">
                <button class="btn dropdown-toggle" type="button" id="funnelLevels" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"> Levels</button>

                <div class="dropdown-menu" aria-labelledby="funnelLevels" x-placement="bottom-start">
                  <button class="dropdown-item"
                          v-for="(item, index) in funnelLevels" v-bind:key="index"
                          v-on:click.stop="openContent(index)">
                    {{ item.name }}
                  </button>
                </div>
              </div>

            </div>

            <div class="mr-1">
              <div class="dropdown">
                <button class="btn dropdown-toggle" type="button" id="funnelProductType" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"> Product Type</button>

                <div class="dropdown-menu" aria-labelledby="funnelProductType" x-placement="bottom-start">
                  <button class="dropdown-item" v-for="(item, index) in funnelProductType" v-bind:key="index" v-on:click.stop="openContent(index)">
                    {{ item.name }}
                  </button>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>


      <div class="user-view">
        <div class="border-bottom mb-4"></div>
        <table class="table table-striped" aria-label="Associated partners">
          <thead>
          <tr>
            <th scope="col">Name</th>
            <th scope="col">Email</th>
            <th scope="col">Level</th>
            <th scope="col">Partner Type</th>
            <th scope="col">Product Type</th>
          </tr>
          </thead>
          <tbody v-if="!errorExist()">
          <tr v-for="(partner, index) in myAssociatedPartners" v-bind:key="index">
            <td>{{ partner.partner_manager_name }}</td>
            <td>{{ partner.partner_manager_email }}</td>
            <td>??</td>
            <td>??</td>
            <td>??</td>
          </tr>
          </tbody>
          <tbody v-if="errorExist()">
          <tr>
            <td colspan="5">{{ errorMsg }}</td>
          </tr>
          </tbody>
        </table>
      </div>
    </div>
    <UserList v-if="visibility.widget_manage_logins"></UserList>
  </div>
</template>

<script>
import axios from "axios";
import UserList from "./UserList";
import apiService from "../../plugins/api";

export default {
  name: 'ViewPartner',
  components: {UserList},
  data() {
    return {
      visibility: {
        partner_edit_link: false,
        widget_assoc_partners: false,
        widget_manage_logins: false,
      },
      loading: true,
      partnerObject: {},
      partner: {},
      errors: {},
      stateOptions: [
        {value: '', title: 'Select One'},
        {value: 'AL', title: 'Alabama'},
        {value: 'AK', title: 'Alaska'},
        {value: 'AZ', title: 'Arizona'},
        {value: 'AR', title: 'Arkansas'},
        {value: 'CA', title: 'California'},
        {value: 'CO', title: 'Colorado'},
        {value: 'CT', title: 'Connecticut'},
        {value: 'DE', title: 'Delaware'},
        {value: 'DC', title: 'District Of Columbia'},
        {value: 'FL', title: 'Florida'},
        {value: 'GA', title: 'Georgia'},
        {value: 'HI', title: 'Hawaii'},
        {value: 'ID', title: 'Idaho'},
        {value: 'IL', title: 'Illinois'},
        {value: 'IN', title: 'Indiana'},
        {value: 'IA', title: 'Iowa'},
        {value: 'KS', title: 'Kansas'},
        {value: 'KY', title: 'Kentucky'},
        {value: 'LA', title: 'Louisiana'},
        {value: 'ME', title: 'Maine'},
        {value: 'MD', title: 'Maryland'},
        {value: 'MA', title: 'Massachusetts'},
        {value: 'MI', title: 'Michigan'},
        {value: 'MN', title: 'Minnesota'},
        {value: 'MS', title: 'Mississippi'},
        {value: 'MO', title: 'Missouri'},
        {value: 'MT', title: 'Montana'},
        {value: 'NE', title: 'Nebraska'},
        {value: 'NV', title: 'Nevada'},
        {value: 'NH', title: 'New Hampshire'},
        {value: 'NJ', title: 'New Jersey'},
        {value: 'NM', title: 'New Mexico'},
        {value: 'NY', title: 'New York'},
        {value: 'NC', title: 'North Carolina'},
        {value: 'ND', title: 'North Dakota'},
        {value: 'OH', title: 'Ohio'},
        {value: 'OK', title: 'Oklahoma'},
        {value: 'OR', title: 'Oregon'},
        {value: 'PA', title: 'Pennsylvania'},
        {value: 'RI', title: 'Rhode Island'},
        {value: 'SC', title: 'South Carolina'},
        {value: 'SD', title: 'South Dakota'},
        {value: 'TN', title: 'Tennessee'},
        {value: 'TX', title: 'Texas'},
        {value: 'UT', title: 'Utah'},
        {value: 'VT', title: 'Vermont'},
        {value: 'VA', title: 'Virginia'},
        {value: 'WA', title: 'Washington'},
        {value: 'WV', title: 'West Virginia'},
        {value: 'WI', title: 'Wisconsin'},
        {value: 'WY', title: 'Wyoming'},
      ],
      funnelLevels: [
        {name: 'Child'},
        {name: 'Grand Child'},
      ],
      funnelPartnerType: [
        {name: 'Vendors'},
        {name: 'Contractors'},
        {name: 'Manufactures'},
      ],
      funnelProductType: [
        {name: 'Equipment Finance'},
        {name: 'Cash Advance'},
        {name: 'Line of Credit'},
        {name: 'Small Business Administration'},
      ],
      myAssociatedPartners: [],
      filters: {
        dateStart: new Date(),
        dateEnd: new Date(),
        allTime: false,
        disabledDates: {
          dateStart: {
            from: '',
          },
          dateEnd: {
            to: '',
          }
        }
      },
      resetFilterDisabled: false,
    }
  },
  methods: {
    fetchData() {
      axios.all([
        apiService().getPartner(this.$route.params.partner_id),
        apiService().getPartnerChildren(this.$route.params.partner_id)
      ])
          .then(axios.spread((partner, children) => {
            this.partner = partner.data;
            this.myAssociatedPartners = children.data;
            this.loading = false;
          }))
          .catch((err) => {
            console.error(err);
          });
    },

    getErrorMsg(paramName, source) {
      const d = this.getParamValue(paramName, source)
      const errorKey = Object.keys(d)[0];
      return d[errorKey];
    },

    getParamValue: function (paramName, source) {
      let d = source;

      paramName.split('.').forEach(function (k) {
        if (typeof (d[k]) !== 'undefined') {
          d = d[k];
        } else {
          d = '';
        }
      });

      return d;//partner
    },

    getListParamValue: function (paramName, fieldInList, source) {
      const recordsList = this.getParamValue(paramName, source);
      const valuesList = [];

      recordsList.forEach(function (k) {
        if (typeof (k[fieldInList]) !== 'undefined') {
          valuesList.push(k[fieldInList]);
        }
      });

      return valuesList.join(', ');
    },

    isParamNotEmpty: function (paramName, source) {
      const value = this.getParamValue(paramName, source);
      return (value || '').length !== 0;
    },

    refreshPage: function () {
      return false;
    },

    cleanAll: function () {
      return false;
    },

    errorExist: function () {
      return this.error;
    },

  },
  mounted() {
    this.fetchData();
  }
};
</script>
